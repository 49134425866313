import React, { Component, useRef } from "react";
import { Link } from "react-router-dom";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";

// import { getPageParameters } from './../libs/utils';
import { API } from "aws-amplify";
import axios from 'axios';
import { getCurrentUser } from '../libs/appData';
import SimpleBarChart from "../components/RenderSimpleBarChart";
import "./HomeV2.css";
import LoadingIndicator from "../components/LoadingIndicator";
import TimeBasedLineChart from '../components/TimeBasedLineChart';

const vid2 = require('../img/images/vid2.png')
const umLogo = require('../img/images/um_logo.jpeg')
const inquiryLogo = require('../img/images/inquiry_logo.png')
const hasVideo = true;


export default class HomeV4 extends Component {
    constructor(props) {
        super();
        //debugger;
        this.state = {
            isLoading: true,
            isLoadingTrends: true,
            teacherEmail: encodeURI(getCurrentUser()),
            video_id: 0,
            other_data: [],
            stats_data: null,
            selectedFile: null,
            hasTrends: false,
        }

        const page_data = 'home';
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
            page_title: page_data,
            page_location: `/${page_data}`,
            page_path: `/${page_data}`,
            send_to: 'UA-91361306-3'
        });
    }


    getTeachers = async () => {
        // get each teacher's info if an admin
        const teachers = await API.get('teachers', 'teachers/geteach2');
        //debugger;
        if (teachers) {
            this.setState({ teachers, isLoading: false });

        }
    }

    onChangeHandler = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        });
        console.log(event.target.files[0]);
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData();
        const { selectedFile } = this.state;
        formData.append('inputFile', selectedFile);
        fetch('/api/upload', {
            method: 'POST',
            body: formData,
        });
    };

    async componentDidMount() {
        this.getTeachers();
        if (this.props.isAuthenticated === true) {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            this.loadDashboardContent();
            this.loadTrends();
        }
    }

    async componentDidUpdate(prevProps) {
        if ((prevProps.isAuthenticated !== this.props.isAuthenticated) && (this.props.isAuthenticated === true)) {
            await this.loadDashboardContent();
        }
    }

    loadDashboardContent = async () => {

        try {
            const other_data = await this.getData();
            if (other_data !== -1) {

                const stats_path = other_data['stats']
                const stats_data = await axios.get(stats_path)
                    .then(data => { return data; })
                    .catch(err => { return err; });

                if (stats_data) {
                    sessionStorage.setItem('stats', JSON.stringify(stats_data.data));
                } else {
                    sessionStorage.setItem('stats', null);
                }
                sessionStorage.setItem('mostRecentVideo', other_data.video_id);
                this.setState({
                    video_id: other_data.video_id,
                    other_data,
                    stats_data: stats_data.data,
                    isLoading: false
                });
            } else {
                this.setState({ other_data })
            }
        } catch (e) {
            // alert(`home page/system error: ${e}`);
            console.log(`home page/system error: ${e}`);
        }
    }

    getData = async () => {
        const data = await API.get("teachers", "teachers/getData/" + this.state.teacherEmail + "?sort=true&fields=all");
        return data;
    }

    loadTrends = async () => {
        let hasTrends = false;
        let videosData = null;
        try {
            videosData = sessionStorage.getItem('videosData');
            if (videosData === 'undefined') {
                videosData = false;
            }
            //debugger;
            if (!videosData) {
                videosData = await API.get("teachers", `teachers/getvideos/${this.state.teacherEmail}/feedback`);
                //debugger;
                if (videosData && videosData.length > 0 && videosData.Items) {
                    sessionStorage.setItem('videosData', JSON.stringify(videosData.Items));
                hasTrends = true;
                }
            }

        } catch {
            hasTrends = false;
        }
        this.setState({ hasTrends, videosData, isLoadingTrends: false })
    }

    goToAnalysis = () => {
        this.props.history(`/video/${this.state.video_id}`);
    }

    renderWithVideoData() {
        return (
            <Col xs={3} sm={5}>

                <Card style={{ width: '400px', }}>
                    <Card.Body>
                        <Card.Title className="title">
                            Most Recent {this.props.lessonLabel}
                            <div style={{ float: 'right', fontWeight: 200 }}>{this.state.other_data['video_date']}</div>
                        </Card.Title>
                        <Card.Text className="text" style={{ fontSize: 12 }}>
                            Video title: {this.state.other_data['video_name']}
                        </Card.Text>
                        <div style={{ position: 'relative' }}>
                            <video width="358" height="auto" style={{ float: 'left' }} controls>
                                {this.state.isLoading === false && (
                                    <source src={this.state.other_data['video']} type="video/mp4" />
                                )}
                            </video>
                            {/* <div style={{background: '#cccccc03', position: 'absolute', height: 200, width: 358, cursor: 'pointer'}}  onClick={this.goToAnalysi 
                            </div> */}
                        </div>
                        {this.state.isLoading ?
                            <LoadingIndicator message="Loading data..." />
                            :
                            <div>

                                <SimpleBarChart stats_data={this.state.stats_data} video_id={this.state.video_id} />
                                <div style={{ textAlign: 'center' }}>
                                    <Link to={`/video/${this.state.video_id}`} className="btn btn-info btn-admindash btn-vert" style={{ minWidth: 'auto', marginTop: 30 }}>
                                        See My Feedback
                                    </Link>
                                </div>

                                <hr />
                                <div style={{ width: '90%', margin: 'auto', fontStyle: 'italic' }}>
                                    <div>
                                        <Link to="/videos" >
                                            See all of My Videos
                                        </Link>
                                    </div>
                                    <div style={{ fontSize: 17 }}>
                                        <b>The Talk Moves Application:</b> <br />Real-time feedback helping mathematics teachers foster effective class discuss
                                    </div>
                                </div>
                            </div>
                        }
                    </Card.Body>
                </Card>
            </Col>
        )
    }

    renderWithOutVD() {
        return (
            <div>
                No videos to display
            </div>
        )
    }


    render() {
        return (
            <div className="Report ">
                {/* <div className="page-header">
                    Dashboard
                </div> */}
                <Container fluid>
                    <Row className="justify-content-md-center">
                        <div style={{ fontSize: 22, marginTop: 10, marginBottom: 44 }}>
                            <b style={{ color: '#17a2b8' }}>Talk Moves:</b><i> Real-time feedback helping mathematics teachers foster effective class discussion</i>
                        </div>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col xs={8} sm={7}>
                            <Card style={{ padding: 24, marginBottom: 24 }}>
                                <h4>What are Talk Moves?</h4>
                                <br />
                                <span style={{ fontSize: 14 }}>
                                    <p>
                                        Talk Moves refers to discussion strategies to promote
                                        equitable participation in a rigorous classroom
                                        learning environment.  Teachers and students can both use Talk Moves
                                        to encourage participation and engagement with math content and reasoning.

                                    </p>
                                    <p>
                                        The Talk Moves application provides feedback on the
                                        following <b>teacher</b> and <b>student</b> Talk Moves:
                                    </p>

                                </span>

                                <div>
                                    <table className='tmTable'>
                                        <tr>
                                            <td style={{ width: 112 }}></td>
                                            <td style={{ background: '#f6cc46', textAlign: 'center', fontWeight: 600 }}>Learning Community</td>
                                            <td style={{ background: '#3cc9c5', textAlign: 'center', fontWeight: 600 }}>Content Knowledge</td>
                                            <td style={{ background: '#ff7358', textAlign: 'center', fontWeight: 600 }}>Rigorous Thinking</td>
                                        </tr>
                                        <tr>
                                            <td><b>Teacher Talk Moves</b></td>
                                            <td>Keep Together<br />Students Relate<br />Restating</td>
                                            <td>Accuracy</td>
                                            <td>Revoicing<br />Reasoning</td>
                                        </tr>
                                        <tr>
                                            <td style={{ paddingTop: 22, paddingBottom: 24 }}><b>Student Talk Moves</b></td>
                                            <td>Relate to Another Student<br />Ask for More Info</td>
                                            <td>Making a Claim</td>
                                            <td>Providing Evidence</td>
                                        </tr>
                                    </table>
                                    <div style={{ width: '50%', margin: 'auto', marginTop: 24 }}>
                                        <div>
                                            <Link to="/definitions" >
                                                Talk Moves Definitions and Examples
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Row className="justify-content-md-center">
                                <Col xs={12}>

                                </Col>
                            </Row>
                        </Col>
                        {this.videosData ? this.renderWithVideoData() : this.renderWithOutVD()}
                    </Row>
                </Container>


                {/* Example trend chart and CTA*/}
                <Container fluid style={{ marginTop: 48 }}>
                    {this.state.isLoadingTrends ?
                        (
                            <LoadingIndicator message='Loading trends...' />
                        ) : (
                            <Row>
                                <Col xs={8} sm={7}>
                                    {this.state.hasTrends ? (
                                        <>
                                            <TimeBasedLineChart
                                                videosData={this.state.videosData}
                                                history={this.props.history}
                                            />
                                            <Row>
                                                <div style={{ width: '50%', textAlign: 'center', margin: 'auto' }}>
                                                    <Link to={`/trends`} className="btn btn-info btn-admindash btn-vert" style={{ minWidth: 'auto', marginTop: 30 }}>
                                                        See {this.props.lessonLabel} Trends
                                                    </Link>
                                                </div>
                                            </Row>
                                        </>
                                    )
                                        : <div>No Trend Data Available</div>
                                    }
                                </Col>
                                <Col xs={4} sm={5}>
                                    <div style={{ width: '90%', margin: 'auto', fontStyle: 'italic' }}>

                                        <div className='trend-pullquote'>
                                            <div style={{ fontSize: 48, fontWeight: 600, fontFamily: 'serif' }} className='flipH'>&#8221;</div>
                                            Intentionally and skillfully using Talk Moves takes time  and practice. And the payoff is worth it!  Talk Moves helps to ensure that classroom discussions will be purposeful, coherent, and productive.
                                            <span style={{ fontSize: 48, fontWeight: 600, fontFamily: 'serif' }}>&#x201D;</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )
                    }

                </Container>
                
                <Container fluid style={{ marginTop: 48 }}>
                    <Card className='grey-btm-border'>
                        <Card style={{ width: 670 }}>
                            <Card.Body>
                                <div className="flex-column-nowrap">
                                    <h5 style={{ width: '100%', textAlign: 'left', marginBottom: 32, marginTop: 16 }}>Talk Moves in Action</h5>

                                    {/* <iframe id="kaltura_player_1600925694" src="https://cdnapisec.kaltura.com/p/1038472/sp/103847200/embedIframeJs/uiconf_id/46290962/partner_id/1038472?iframeembed=true&playerId=kaltura_player_1600925694&entry_id=1_s4wld1zv" width="360" height="239" allowfullscreen webkitallowfullscreen mozAllowFullScreen allow="autoplay *; fullscreen *; encrypted-Card *" frameborder="0"></iframe>
                                   <a target="_blank" rel="noopener noreferrer" href="https://tle.soe.umich.edu">
                                       <img src={umLogo} style={{maxWidth: 350, padding: '2rem'}} alt="The Inquiry Project logo. Seeing the world through a scientist's eyes" /></a> */}

                                    <iframe id="kaltura_player_1601404743" src="https://cdnapisec.kaltura.com/p/1038472/sp/103847200/embedIframeJs/uiconf_id/46424261/partner_id/1038472?iframeembed=true&playerId=kaltura_player_1601404743&entry_id=1_b8p97z5r"
                                        width="560" height="395" allowfullscreen webkitallowfullscreen mozAllowFullScreen
                                        allow="autoplay *; fullscreen *; encrypted-Card *" frameborder="0"></iframe>
                                    <a target="_blank" rel="noopener noreferrer" href="https://tle.soe.umich.edu">
                                        <img src={umLogo} style={{ maxWidth: 350, padding: '2rem' }} alt="The Inquiry Project logo. Seeing the world through a scientist's eyes" /></a>

                                </div>
                            </Card.Body>
                        </Card>
                        <Card.Body className="padding-left-three">
                            {/* <h5>Talk Moves In Action!</h5> */}
                            <p style={{ paddingTop: 84 }}>
                                In this video, rising fifth graders from a summer mathematics class discuss a “warm up problem” where they identify fractions as points on a number line. Note the ways that the instructor, Dr. Deborah Ball, encourages students to share their thinking and respond directly to each other. Student voices play a key role, with both the instructor and students using a variety of talk moves. </p>
                            <div>
                                {/* <a target="_blank" rel="noopener noreferrer" href="https://deepblue.lib.umich.edu/handle/2027.42/134321"><Button variant="outline-info" style={{marginRight: '1rem'}}>Watch the Video</Button></a> */}
                                <a target="_blank" rel="noopener noreferrer" href="https://talkmoves-content.s3.amazonaws.com/Talkmoves+in+Action+-+Coded+Transcript.pdf"><Button variant="outline-secondary" style={{ marginRight: '1rem' }} >View the Coded Transcript</Button></a>
                                <Button href={'/definitions'} variant="outline-secondary" >Definitions and Examples</Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Container>

                {/* TALK MOVES BREAKDOWN*/}
                <Container style={{ display: 'none' }}>
                    <h3 style={{ textAlign: 'center', margin: 24 }}>
                        Teacher Talk Moves
                    </h3>
                    {/* Learning Community */}
                    <Row className="justify-content-md-center">
                        <div style={{ position: 'relative' }}>
                            <div style={{ width: '80%', margin: 'auto', textAlign: 'center', border: '1px solid #ddd', borderRadius: 5 }}>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(246, 204, 70)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Learning Community
                                        </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Keep Together</span>
                                    <div style={{ margin: '24px 64px' }}>Keeping everyone together.
                                        Prompting students to be active listeners
                                        Orienting students to each other
                                    </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Teacher: What did Eliza just say her equation was?</li>
                                        <li>Teacher: Turn and talk to your partner.</li>
                                        <li>Teacher: Chris, what do you think?</li>
                                        <li>Teacher: Does anyone have questions?</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div style={{ width: '80%', margin: 'auto', textAlign: 'center', border: '1px solid #ddd', borderRadius: 5 }}>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(246, 204, 70)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Learning Community
                                        </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Student Relate</span>
                                    <div style={{ margin: '24px 64px' }}>Getting students to relate to another’s idea
                                        Prompting students to react to what a classmate said
                                    </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Teacher: Do you agree with Juan that the answer is 7/10?</li>
                                        <li>Teacher: Can someone add on to what Maria just said?</li>
                                        <li>Teacher: Does everyone understand how Carla solved the problem?</li>
                                        <li>Teacher: Who else used David’s method?</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div style={{ width: 'max-content', margin: 'auto', textAlign: 'center', border: '1px solid #ddd', borderRadius: 5 }}>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(246, 204, 70)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Learning Community
                                        </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Restarting</span>
                                    <div style={{ margin: '24px 64px' }}>Repeating all or part of what
                                        a student says word for word
                                    </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Student: It’s in the minus four.</li>
                                        <li>Teacher: It’s in the minus four.</li>
                                        <br />
                                        <li>Student: An exponent.</li>
                                        <li>Teacher: Exponent.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Row>
                    {/* Content Knowledge */}
                    <Row className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div style={{ width: '80%', margin: 'auto', textAlign: 'center', border: '1px solid #ddd', borderRadius: 5 }}>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(61, 201, 196)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Content Knowledge
                                        </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Accuracy</span>
                                    <div style={{ margin: '24px 64px' }}>Pressing for accuracy.
                                        Prompting students to make a mathematical contribution or use mathematical language
                                    </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Teacher: Can you give an example of an ordered pair?</li>
                                        <li>Teacher: What’s another word for that?</li>
                                        <li>Teacher: What does the <i>x</i> stand for?</li>
                                        <li>Teacher: What would I write next?</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Row>
                    {/* Rigorous Thinking */}
                    <Row className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div style={{ width: '80%', margin: 'auto', textAlign: 'center', border: '1px solid #ddd', borderRadius: 5 }}>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(255, 115, 89)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Rigorous Thinking
                                        </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Revoicing</span>
                                    <div style={{ margin: '24px 64px' }}>Repeating what a student says but adding on to it or changing the wording.
                                        Using at least one key mathematical word or key idea from what a student said

                                    </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Student: It had two.</li>
                                        <li>Teacher: So instead of one flat edge, it had two.</li>
                                        <br />
                                        <li>Student: Oh, company B.</li>
                                        <li>Teacher: It’s company B because that’s the one that charges you $2.00 per minute.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div style={{ width: '80%', margin: 'auto', textAlign: 'center', border: '1px solid #ddd', borderRadius: 5 }}>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(255, 115, 89)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Rigorous Thinking
                                        </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Reasoning</span>
                                    <div style={{ margin: '24px 64px' }}>Pressing for reasoning.
                                        Prompting students to explain or provide evidence, share
                                        their thinking behind a  decision, or connect ideas or representations
                                    </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Teacher: What made you decide to extend the line?</li>
                                        <li>Teacher: Why would we add 2 here?</li>
                                        <li>Teacher: Why could I argue that the slope should be increasing?</li>
                                        <li>Teacher: Where do we see x+1 in the tiles?</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
            // :
            // <div>hello</div> }
        );
    }
}
