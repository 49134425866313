import React, { Component } from "react";
//import { Link } from "react-router-dom";
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { Container, Button } from "react-bootstrap";

// import { getPageParameters } from './../libs/utils';
//import { API } from "aws-amplify";
//import axios from 'axios';
//import { getCurrentUser } from './../libs/appData';
//import SimpleBarChart from "../components/RenderSimpleBarChart";
import "./HomeV2.css";
//import LoadingIndicator from "../components/LoadingIndicator";
const umLogo = require('../img/images/um_logo.jpeg')
const pdfURL = 'https://talkmoves-content.s3.amazonaws.com/Talk+Moves+Defined+(teacher+%26+student)-test.pdf';

export default class Definitions extends Component {

    constructor(props) {
        super();
        let pos;
        if(window.location.hash) {
            pos = window.location.hash;
            pos = pos.substring(1);
          } else {
            pos = null
          }
        this.state = {
            navMargin: 0,
            navSelected: 0,
            initialPos: pos,
        }
        this.navClick = this.navClick.bind(this);

        const page_data = 'definitions';
        // eslint-disable-next-line no-undef
        gtag('event', 'page_view', {
            page_title: page_data,
            page_location: `/${page_data}`,
            page_path: `/${page_data}`,
            send_to: 'UA-91361306-3'
          });
    }
    navClick(e) {
        this.setState(
        {
            navSelected: e.currentTarget.id
        });
    }

    componentDidMount() {
        if(this.state.initialPos) {
            this.initialScroll();
        } else {
            window.scrollTo({top: 0, left: 0,  behavior: 'smooth'});
        }
        window.addEventListener('scroll', (e) => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 90) {
                this.setState({ navMargin: scrollPosition-80 })
            } else {
                this.setState({ navMargin: 0 })
            }
        });
        // if(window.location.hash) {
        //     this.setState({pre initialPos: window.location.hash})
        //   } else {
        //     this.setState({ initialPos: null })
        //   }
    }

    initialScroll = () =>{
            const target = document.getElementById(this.state.initialPos);
            target.scrollIntoView({behavior: 'smooth'})
    }

    scrollTo = id => {
        const scrollToElement = document.getElementById(id);
        scrollToElement.scrollIntoView({ behavior: 'smooth' });
    };


    render() {
        return (
            <div className="Report">
                <h3 id="ttm" style={{ textAlign: 'center', margin: 24, paddingLeft: 200 }}>
                    Talk Moves Definitions and Examples
                </h3>
                {/* TALK MOVES BREAKDOWN*/}
                {/* <div className='cascasdingLeftNav' style={{ marginTop: this.state.navMargin }}> */}
                <div className='video-fixed-nav'>
                    <h4 id="1" onClick={this.navClick} className={this.state.navSelected === '1' ? "blueText" : "greyText"} style={{cursor: 'pointer' }} >
                    <a onClick={() => { this.scrollTo('ttm') }}>Teacher Talk Moves</a>
                    </h4>
                    {/* <div>
                        <a onClick={() => { this.scrollTo('ttm') }}>Keeping Together</a>
                    </div>
                    <div>
                        <a onClick={() => { this.scrollTo('ttm-relating') }}>Students Relating</a>
                    </div>
                    <div>
                        <a onClick={() => { this.scrollTo('ttm-restating') }}>Students Restating</a>
                    </div>
                    <div>
                        <a onClick={() => { this.scrollTo('ttm-accuracy') }}>Accuracy</a>
                    </div>
                    <div>
                        <a onClick={() => { this.scrollTo('ttm-revoicing') }}>Revoicing</a>
                    </div>
                    <div>
                        <a onClick={() => { this.scrollTo('ttm-reasoning') }}>Reasoning</a>
                    </div> */}

                    <h4 id='2' onClick={this.navClick} className={this.state.navSelected === '2' ? "blueText" : "greyText"} style={{marginTop: 24, cursor: 'pointer'}}>
                    <a onClick={() => { this.scrollTo('stm') }}>Student Talk Moves</a>
                    </h4>

                    {/* <div>
                        <a onClick={() => { this.scrollTo('stm') }}>Keeping Together</a>
                    </div>
                    <div>
                        <a onClick={() => { this.scrollTo('ttm-relating') }}>Students Relating</a>
                    </div>
                    <div>
                        <a onClick={() => { this.scrollTo('ttm-restating') }}>Students Restating</a>
                    </div>
                    <div>
                        <a onClick={() => { this.scrollTo('ttm-accuracy') }}>Accuracy</a>
                    </div>
                    <div>
                        <a onClick={() => { this.scrollTo('ttm-revoicing') }}>Revoicing</a>
                    </div>
                    <div>
                        <a onClick={() => { this.scrollTo('ttm-reasoning') }}>Reasoning</a>
                    </div> */}
                    {/* <h4 id='3'onClick={this.navClick} className={this.state.navSelected === '3' ? "blueText" : "greyText"} style={{ marginTop: 24, cursor: 'pointer'}}>
                    <a onClick={() => { this.scrollTo('videos') }}>Video Examples</a>
                    </h4> */}
                </div>
                {/* <div className='contentWithLeftNav'> */}
                <div className='lessons-content-area'>

                    {/* <Container> */}
                    {/* Learning Community */}
                    <div className="anchor" id="ttm1"></div>
                    <h4 style={{ width: '100%', margin: 48, marginBottom: 24 }}> Teacher Talk Moves
                        <Button 
                            style={{position: 'absolute', right: '48px', fontFamily: '"Open Sans", sans'}} size="sm" 
                            className="btn btn-info btn-vert">
                         <a href={pdfURL} download="Talk Moves Definitions" style={{color: 'white'}}>Download Definitions</a>
                        </Button>
                    </h4>
                    <div style={{margin: '0px 56px 40px 70px', fontStyle: 'italic'}}>
                    Teacher talk moves refer to discussion strategies that teachers can use to promote students’ equitable participation in a rigorous classroom learning environment. Talk moves encourage students to contribute and listen to each other, engage with the math content, and dig deeply into their own reasoning.
                    </div>
                   {/* Teacher Learning Community */}
                    <div id="ttm-together" className="justify-content-md-center">
                        <div style={{ position: 'relative' }}>
                            <div className="definitionContainer" >
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(246, 204, 70)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Learning Community
                                </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Keep Together</span>
                                    <div style={{ margin: '24px 64px' }}>Keeping everyone together.<br />
                                    Prompting students to be active listeners.<br />
                                    Orienting students to each other.
                            </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Teacher: What did Eliza just say her equation was?</li>
                                        <li>Teacher: Turn and talk to your partner.</li>
                                        <li>Teacher: Chris, what do you think?</li>
                                        <li>Teacher: Does anyone have questions?</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="ttm-relating" className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div className="definitionContainer" >
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(246, 204, 70)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Learning Community
                                </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Student Relate</span>
                                    <div style={{ margin: '24px 64px' }}>Getting students to relate to another’s idea.<br />
                                    Prompting students to react to what a classmate said.
                            </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Teacher: Do you agree with Juan that the answer is 7/10?</li>
                                        <li>Teacher: Can someone add on to what Maria just said?</li>
                                        <li>Teacher: Does everyone understand how Carla solved the problem?</li>
                                        <li>Teacher: Who else used David’s method?</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="ttm-restating" className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div className="definitionContainer" >
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(246, 204, 70)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Learning Community
                                </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Restating</span>
                                    <div style={{ margin: '24px 64px' }}>Repeating all or part of what
                                    a student says word for word.
                            </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Student: It’s in the minus four.</li>
                                        <li>Teacher: It’s in the minus four.</li>
                                        <br />
                                        <li>Student: An exponent.</li>
                                        <li>Teacher: Exponent.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Content Knowledge */}
                    <div id="ttm-accuracy" className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div className="definitionContainer" >
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(61, 201, 196)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Content Knowledge
                                </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Accuracy</span>
                                    <div style={{ margin: '24px 64px' }}>Pressing for accuracy.<br />
                                    Prompting students to make a mathematical contribution or use mathematical language.
                            </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Teacher: Can you give an example of an ordered pair?</li>
                                        <li>Teacher: What’s another word for that?</li>
                                        <li>Teacher: What does the <i>x</i> stand for?</li>
                                        <li>Teacher: What would I write next?</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Rigorous Thinking */}
                    <div id="ttm-revoicing" className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div className="definitionContainer" >                           
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(255, 115, 89)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Rigorous Thinking
                                </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Revoicing</span>
                                    <div style={{ margin: '24px 64px' }}>Repeating what a student says but adding on to it or changing the wording.
                                    <br />Using at least one key mathematical word or key idea from what a student said.

                            </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Student: It had two.</li>
                                        <li>Teacher: So instead of one flat edge, it had two.</li>
                                        <br />
                                        <li>Student: Oh, company B.</li>
                                        <li>Teacher: It’s company B because that’s the one that charges you $2.00 per minute.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="ttm-reasoning" className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div className="definitionContainer" >
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(255, 115, 89)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Rigorous Thinking
                                </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Reasoning</span>
                                    <div style={{ margin: '24px 64px' }}>Pressing for reasoning.<br />
                                    Prompting students to explain or provide evidence, share
                                    their thinking behind a  decision, or connect ideas or representations.
                            </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Teacher: What made you decide to extend the line?</li>
                                        <li>Teacher: Why would we add 2 here?</li>
                                        <li>Teacher: Why could I argue that the slope should be increasing?</li>
                                        <li>Teacher: Where do we see x+1 in the tiles?</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* STUDENT TALK MOVES */}
		            <div className="anchor" id="stm"></div>
                        <h4 style={{margin:48, marginBottom: 24}}> Student Talk Moves</h4>
                    <div style={{margin: '0px 56px 40px 70px', fontStyle: 'italic'}}>
                        Student talk moves are math-related statements or questions made by students that help them to 1) link their ideas to others in the classroom community, 2) assert their knowledge, or 3) share their reasoning.
                    </div>
                    <div id="stm-relating" className="justify-content-md-center">
                        <div style={{ position: 'relative' }}>
                            <div className="definitionContainer" >
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(246, 204, 70)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Learning Community
                                </div>
                                    </div>
                                    <span style={{ fontSize: 42, lineHeight: '50px' }}>Relating to <br />Another Student</span>
                                    <div style={{ margin: '24px 64px' }}>
                                        Use classmate’s ideas as a basis for own idea.<br />
                                        Comment on another student’s idea.<br />
                                        Ask questions about another student’s idea.
                            </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Student: I didn’t get the same answer.</li>
                                        <li>Student: I was going to say what Shane said.</li>
                                        <br />
                                        <li>Teacher: What’s the name of the point Gabe just drew?</li>
                                        <li>Student: The y-intercept.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="stm-asking" className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div className="definitionContainer" >
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(246, 204, 70)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Learning Community
                                </div>
                                    </div>
                                    <span style={{ fontSize: 42, lineHeight: '50px' }}>Asking for <br />More Info</span>
                                    <div style={{ margin: '24px 64px' }}>Student requests  more information about a math topic.
                                        <br />Student says they are confused or need help.
                            </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Student: Can you help me with number four please?</li>
                                        <li>Student: Is it positive or negative?</li>
                                        <li>Student: I don't understand.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Content Knowledge */}

                    <div id="stm-claim" className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div className="definitionContainer" >
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(61, 201, 196)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Content Knowledge
                                </div>
                                    </div>
                                    <span style={{ fontSize: 42 }}>Making a Claim</span>
                                    <div style={{ margin: '24px 64px' }}>
                                    Student makes a mathematical claim.<br />
                                    Student makes a factual statement about a math concept.<br />
                                    Student lists one step in how they arrived  at their answer.


                            </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Student: It's the same as number two.</li>
                                        <li>Student: X is the number of cars.</li>
                                        <li>Student: I got y = 0.14x.</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Rigorous Thinking */}

                    <div id="stm-evidence" className="justify-content-md-center" style={{ marginTop: 24 }}>
                        <div style={{ position: 'relative' }}>
                            <div className="definitionContainer" >
                                 <div style={{ width: '50%', display: 'inline-block', minHeight: 400, backgroundColor: 'white' }}>
                                    <div style={{ textAlign: 'left' }}>
                                        <div style={{ backgroundColor: 'rgb(255, 115, 89)', padding: 12, fontSize: 18, marginBottom: 60, width: 'fit-content' }}>
                                            Rigorous Thinking
                                </div>
                                    </div>
                                    <span style={{ fontSize: 42, lineHeight: '50px' }}>Providing Evidence/Explanation</span>
                                    <div style={{ margin: '24px 64px' }}>
                                    Student explains their thinking.<br />
                                    Student provides evidence for a mathematical claim.<br />
                                    Student talks about their reasoning.

                            </div>
                                </div>
                                <div style={{ width: '50%', display: 'inline-block', minHeight: 400, paddingTop: 86, backgroundColor: '#ddd          ', verticalAlign: 'top', textAlign: 'left', fontSize: 20 }}>
                                    <ul>
                                        <li>Tutor: What pattern do you see?</li>
                                        <li>Student: They all go up by 50.</li>
                                        <br />
                                        <li>Student: Five times four is the same as four times five. So, the answer is 20.</li>
                                        <li>Student: You can't subtract 7 because then you would only get 28 and you need 29.</li>
                                     </ul>
                                </div>
                            </div>
                        </div>
                    </div> 

                    {/* VIDEOS */}
                    {/* <div className="anchor" id="videos"></div> */}
                </div>
            </div>
        );
    }
}
