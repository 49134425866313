import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import axios from 'axios';

import { getCurrentUser } from './../libs/appData';

import { Row, Col, Card, Container } from "react-bootstrap";
import TeacherStudentPieChart from "../components/RenderTeacherStudentPieChart";
import TalkmovesPieChart from "../components/RenderTalkmovesPieChart";
import SimpleBarChart from "../components/RenderSimpleBarChart";
import StackedBarChart from "../components/RenderStackedBarChart";
import LoadingIndicator from "../components/LoadingIndicator";

import packageJSON from "../../package.json";
import "./Home.css";
import "./Global.css";

const logo = require('../img/logo/logo.png');


export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            video_id: 0,
            other_data: [],
            stats_data: null,
            version: packageJSON.version,
        };
    }

    async componentDidMount () {
        try {
            const versionTime = await API.get(`settings`, `settings`);
        } catch (err) {
            console.log('settings err', err);
        }
        if (this.props.isAuthenticated === true) {
            const { isAdmin, isCoach, isHATResearcher, shadowName } = this.props;
            const isAdminDashboard = (isAdmin && !shadowName) || false;
            if (isAdminDashboard === false) {
                if (isHATResearcher ) {
                    this.goHome('/hat-researcher-home');
                } else if (isCoach) {
                    this.goHome('/coach-home');
                }
                else {
                    this.goHome('/newhome');
                }
            } else {
                await this.loadDashboardContent();
            }
        }
    }

    // goHome 
    //   Utility method to take this particular user to their home page
    goHome = (pageURL) => {
        this.props.history(pageURL);
    };

    async componentDidUpdate(prevProps) {
        if ((prevProps.isAuthenticated !== this.props.isAuthenticated) && (this.props.isAuthenticated === true)) {
            await this.loadDashboardContent();
        }
    }

    loadDashboardContent = async () => {
        const { isAdmin, shadowName } = this.props;
        const isAdminDashboard = (isAdmin && !shadowName) || false;
        if (isAdminDashboard) {
            this.setState({ isLoading:false });
            return;
        }
        try {
            const other_data = await this.getData();
            if (other_data !== -1) {
                
                const stats_path = other_data['stats']
                const stats_data = await axios.get(stats_path)
                    .then( data => { return data; })
                    .catch( err => { return err; });

                    if (stats_data) {
                        sessionStorage.setItem('stats', JSON.stringify(stats_data.data));
                    } else {
                        sessionStorage.setItem('stats', null);
                    }
                this.setState({
                    video_id: other_data.video_id,
                    other_data,
                    stats_data: stats_data.data,
                    isLoading: false
                });
            } else {
                this.setState({ other_data })
            }
        } catch (e) {
            alert(e);
        }
    }

    getData = async() => {
        const email = encodeURI(getCurrentUser());
        const data = await API.get("teachers", "teachers/getData/" + email + "?sort=true&fields=all");
        return data;
    }

    renderLander() {
        return (
            <div className="lander-old" style={{padding: 0}}>
            <Container fluid>
                {/* <h1>
                    <img src={logo} alt='TalkMove Logo' style={{ display: 'inline-block', width: '80%', maxWidth: 360 }} />
                </h1> */}
                {/* <p>Moving feedback on how math teachers talk</p> */}
                <Row className="justify-content-md-center">
                    <div style={{fontSize: 22, marginTop: 10, marginBottom: 44}}>
                        <b style={{color: '#17a2b8'}}>Talk Moves:</b><i> Real-time feedback helping mathematics teachers foster effective class discussion</i>
                    </div>
                </Row>
                <Row className="justify-content-md-center">
                        <Col xs={8} sm={7}>
                            <Card style={{ padding: 24, marginBottom: 24 }}>
                                <h4>What are Talk Moves?</h4>
                                <br />
                                <span style={{ fontSize: 14 }}>
                                    <p>
                                        Talk Moves refers to discussion strategies to promote 
                                        equitable participation in a rigorous classroom 
                                        learning environment.  Teachers and students can both use Talk Moves 
                                        to encourage participation and engagement with math content and reasoning.

                        </p>
                                    <p>
                                        The Talk Moves application provides feedback on the 
                                        following <b>teacher</b> and <b>student</b> Talk Moves:
                        </p>
   
                                </span>
                                <div>
                                    <table className='tmTable'>
                                        <tr>
                                            <td style={{width: 112}}></td>
                                            <td style={{background:'#f6cc46', textAlign: 'center', fontWeight: 600}}>Learning Community</td>
                                            <td style={{background:'#3cc9c5', textAlign: 'center', fontWeight: 600}}>Content Knowledge</td>
                                            <td style={{background:'#ff7358', textAlign: 'center', fontWeight: 600}}>Rigorous Thinking</td>
                                        </tr>
                                        <tr>
                                            <td><b>Teacher Talk Moves</b></td>
                                            <td>Keep Together<br/>Students Relate<br/>Restating</td>
                                            <td>Accuracy</td>
                                            <td>Revoicing<br/>Reasoning</td>
                                        </tr>
                                        <tr>
                                            <td style={{paddingTop: 22, paddingBottom: 24}}><b>Student Talk Moves</b></td>
                                            <td>Relate to Another Student<br/>Ask for More Info</td>
                                            <td>Making a Claim</td>
                                            <td>Providing Evidence</td>
                                        </tr>
                                    </table>
                                    <div style={{ width: '50%', margin: 'auto' , marginTop: 24 }}>
                                                <div>
                                                    <Link to="/definitions" >
                                                       Talk Moves Definitions and Examples 
                                                     </Link>
                                                </div>
                                            </div>
                                </div>
                            </Card>
                            <Row className="justify-content-md-center">
                        <Col xs={12}>

                        </Col>
                    </Row>
                        </Col>
                        <Col xs={3} sm={5} style={{textAlign: 'center', padding: 48}}>
                        <div>
                    <Link to="/sample-video" className="btn btn-info btn-lg" style={{marginBottom: 24}}>
                        Sample Lesson
                    </Link>
                    </div>
                    <div>
                    <Link to="/sample-trends" className="btn btn-info btn-lg" style={{marginBottom: 24}}>
                        Sample Trends
                    </Link>

                    {/* <Link to="/signup" className="btn btn-success btn-lg">
                        Register
                    </Link> */}
                </div>
                <div >
                    <Link to="/login" className="btn btn-info btn-lg" style={{margin: 18}}>
                        Sign in
                    </Link>
                    {/* <Link to="/signup" className="btn btn-success btn-lg">
                        Register
                    </Link> */}
                        <Link to="/signup" className="btn btn-info btn-lg" style={{margin: 18}}>
                        Sign Up
                    </Link>
                </div>

                </Col>
                </Row>
                    
            </Container>
            </div>
        );
    }

    renderAdminDashboard() {

        return (
            <div style={{paddingLeft: 120}}>
                <div style={{width: '50%', minWidth: 250, maxWidth: 400, display: 'inline-block'}}>
                <div>
                    <Link to="/shadow" className="btn btn-info btn-admindash btn-vert">
                        Set User
                    </Link>
                </div>
                <div>
                    <Link to="/videos" className="btn btn-info btn-admindash btn-vert">
                        My Videos
                    </Link>
                </div>
                <div>
                    <Link to="/newhome" className="btn btn-info btn-admindash btn-vert">
                        Test Pages
                    </Link>
                </div>
                </div>
                <div style={{width: '50%', minWidth: 250, maxWidth: 400, display: 'inline-block'}}>
                <div>
                    <Link to="/reports?start=teacher" className="btn btn-info btn-admindash btn-vert">
                        Lesson-by-Lesson Data
                    </Link>
                </div>
                <div>
                    <Link to="/reports?start=aggregate" className="btn btn-info btn-admindash btn-vert">
                        Aggregated Data
                    </Link>
                <div>
                    <Link to="/reports?start=uploads" className="btn btn-info btn-admindash btn-vert">
                        Uploads
                    </Link>
                </div>
                </div>
                </div>
            </div>
        );
    }

    renderTeacherDashboard() {
        const { isLoading } = this.state;
        return (
            <Row>
            { isLoading === false && (
            <Card style={{ width: '824px', margin: "8px 12px" }}>
                <Card.Body>
                    <Card.Title className="title"><i className="fab fa-youtube icon"></i> The Latest {this.props.lessonLabel}</Card.Title>
                    <video width="470" height="255" style={{ float: 'right' }} controls>
                        { this.state.isLoading === false && (
                            <source src={this.state.other_data['video']} type="video/mp4" />
                        )}
                    </video>
                    <Card.Text className="text">
                        { isLoading === false && ( this.state.other_data['video_name'] )} <br />
                        { isLoading === false && ( this.state.other_data['video_date'] )}
                    </Card.Text>
                    {/* <Card.Link className="text link" href="/video">WATCH VIDEO <i className="fas fa-arrow-right arrow-icon"></i></Card.Link> */}
                </Card.Body>
            </Card>
            )}

            { isLoading === false && (
            <Card style={{ width: '400px', margin: "8px 12px" }}>
                <Card.Body>
                    <Card.Title className="title"><i className="fas fa-hashtag icon"></i> Frequency of teacher talk moves</Card.Title>
                    { isLoading === false && (
                        <SimpleBarChart stats_data={this.state.stats_data} video_id={this.state.video_id} />
                    )}
                    <br />
                    <Card.Text className="text">
                        {isLoading === false && ( this.state.other_data['video_name'] )} <br />
                        { isLoading === false && ( this.state.other_data['video_date'] )}
                    </Card.Text>
                    <Card.Link className="text link" as={Link} to={ `/frequency/${this.state.video_id}` }>VIEW DETAILS  <i className="fas fa-arrow-right arrow-icon"></i></Card.Link>
                </Card.Body>
            </Card>
            )}

            { isLoading === false && (
            <Card style={{ width: '400px', margin: "8px 12px" }}>
                <Card.Body>
                    <Card.Title className="title"><i className="fas fa-percentage icon"></i> Percent of teacher & student talk</Card.Title>
                    {isLoading === false && (
                        <TeacherStudentPieChart stats_data={this.state.stats_data} video_id={this.state.video_id} />
                    )}
                    <br />
                    <Card.Text className="text">
                        { isLoading === false && ( this.state.other_data['video_name'] )} <br />
                        { isLoading === false && ( this.state.other_data['video_date'] )}
                    </Card.Text>
                    <Card.Link className="text link" as={Link} to={ `/teacherstudent/${this.state.video_id}` }>VIEW DETAILS  <i className="fas fa-arrow-right arrow-icon"></i></Card.Link>
                </Card.Body>
            </Card>
            )}

            { isLoading === false && (
            <Card style={{ width: '400px', margin: "8px 12px" }}>
                <Card.Body>
                    <Card.Title className="title"><i className="fas fa-percentage icon"></i> Percent of talk moves by category</Card.Title>
                    { isLoading === false && (
                        <TalkmovesPieChart stats_data={this.state.stats_data}  video_id={this.state.video_id} />
                    )}
                    <br />
                    <Card.Text className="text">
                        { isLoading === false && ( this.state.other_data['video_name'] )} <br />
                        { isLoading === false && ( this.state.other_data['video_date'] )}
                    </Card.Text>
                    <Card.Link className="text link" as={Link} to={ `/category/${this.state.video_id}` }>VIEW DETAILS  <i className="fas fa-arrow-right arrow-icon"></i></Card.Link>
                </Card.Body>
            </Card>
            )}

            { isLoading === false && (
            <Card style={{ width: '400px', margin: "8px 12px" }}>
                <Card.Body>
                    <Card.Title className="title"><i className="fas fa-hashtag icon"></i> Talk moves by category in each quarter</Card.Title>
                    { this.state.isLoading === false && (
                        <StackedBarChart stats_data={this.state.stats_data} video_id={this.state.video_id} />
                    )}
                    <Card.Text className="text">
                        { this.state.isLoading === false && ( this.state.other_data['video_name'] )} <br />
                        { this.state.isLoading === false && ( this.state.other_data['video_date'] )}
                    </Card.Text>
                    <Card.Link className="text link" as={Link} to={ `/quarter/${this.state.video_id}` }>VIEW DETAILS  <i className="fas fa-arrow-right arrow-icon"></i></Card.Link>
                </Card.Body>
            </Card>
            )}

            { isLoading === false && (
            <Card style={{ width: '400px', margin: "8px 12px" }}>
                <Card.Body>
                    <Card.Title className="title"><i className="fas fa-cloud icon"></i> Word cloud</Card.Title>
                    { this.state.isLoading === false && (
                        <Card.Img variant="top" src={this.state.other_data['word_cloud']} />
                    )}
                    <Card.Text className="text">
                        { this.state.isLoading === false && ( this.state.other_data['video_name'] )} <br />
                        { this.state.isLoading === false && ( this.state.other_data['video_date'] )}
                    </Card.Text>
                    <Card.Link className="text link" as={Link} to={ `/wordcloud/${this.state.video_id}` }>VIEW DETAILS  <i className="fas fa-arrow-right arrow-icon"></i></Card.Link>
                </Card.Body>
            </Card>
            )}
            
            { isLoading === false && (
            <Card style={{ width:'400px', margin:"8px 12px" }}>
                <Card.Body>
                    <Card.Title className="title"><i className="fas fa-info icon"></i> Class engagement</Card.Title>
                    <Card.Text className="text">
                        <div style={{ width: "50%", float: "left" }}>
                            <div style={{ fontSize: "36px", fontFamily: "Roboto, san-serif" }}>
                            { this.state.isLoading === false && (
                                Math.round(this.state.stats_data['student_one_word_percent'])
                            )}% 
                            </div>Student sentences with only a single word
                        </div>
                        <div style={{ width: "50%", float: "right" }}>
                            <div style={{ fontSize: "36px", fontFamily: "Roboto, san-serif" }}>
                            { this.state.isLoading === false && (
                                Math.round(this.state.stats_data['teacher_wait_time_percent'])
                            )}% 
                            </div>Teacher sentences with >3 seconds wait time
                        </div>
                        <div style={{ clear: "both", marginBottom: "20px" }}></div>
                        { this.state.isLoading === false && ( this.state.other_data['video_name'] )} <br />
                        { this.state.isLoading === false && ( this.state.other_data['video_date'] )}
                    </Card.Text>
                    <Card.Link className="text link" as={Link} to={ `/addinfo/${this.state.video_id}` }>VIEW DETAILS  <i className="fas fa-arrow-right arrow-icon"></i></Card.Link>
                </Card.Body>
            </Card>
            )}
        </Row>
        )
    }

    renderPage() {
        const { isLoading } = this.state;
        const { isAdmin, shadowName } = this.props;
        const isAdminDashboard = (isAdmin && !shadowName) || false;
        return (
            <div >
                <div className="page-header">{isAdminDashboard ? 'Research ' : ''}Dashboard</div>
                {this.state.other_data === -1 && this.state.isLoading === false && (
                    <Row><div>You do not have any videos uploaded.</div></Row>
                )}
                {isLoading === true
                    ? <LoadingIndicator message='Loading your dashboard'>Loading</LoadingIndicator>
                    : <>{isAdminDashboard === false 
                        ? <>{this.renderTeacherDashboard()}</>
                        : <>{this.renderAdminDashboard()}</>              
                        }  
                      </>
                }     
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderPage() : this.renderLander()}
            </div>
        );
    }
}