import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import { API } from "aws-amplify";
import axios from "axios";

import {
  Col,
  Row,
} from "react-bootstrap";
import TeacherStudentPieChart from "../components/RenderTeacherStudentPieChart";
import TalkmovesPieChart from "../components/RenderTalkmovesPieChart";
import SimpleBarChart from "../components/RenderSimpleBarChart";
import StackedBarChart from "../components/RenderStackedBarChart";
import benchmarkData from "../data/talkmovesdata.json";

import { getCurrentUser } from "../libs/appData";
import { trackEvent, normalizeProjectTimeStamp } from "./../libs/utils";
import SampleVideoImage from "../img/images/sample-video-blur.png";

import "./Global.css";
import "./Video.css";
import LoadingIndicator from "../components/LoadingIndicator";

const logo = require("../img/logo/logo.png");

let lessonMenu = [
  {
    name: "Video and Wordcloud",
    color: "#0f0",
    id: "this-lesson",
    anchor: "page-top",
  },
  {
    name: "Teacher Talk Moves",
    color: "#0f0",
    id: "menu-ttm",
    anchor: "ttm",
    menuItems: [
      {
        name: "Did the Teacher Use Talk Moves?",
        id: "menu-tm-am-i",
        anchor: "ttm",
      },
      {
        name: "Which Talk Moves Did the Teacher use?",
        id: "menu-tm-which",
        anchor: "ttm-which",
      },
      {
        name: "Did the Teacher Use Talk Moves from Each Category?",
        id: "menu-tm-category",
        anchor: "ttm-category",
      },
      {
        name: "When Did the Teacher Use Talk Moves?",
        id: "menu-tm-when",
        anchor: "ttm-when",
      },
    ],
  },
    
  {
    name: "Student Talk Moves",
    color: "#f00",
    id: "menu-stm",
    anchor: "page-stm",
    menuItems: [
      {
        name: "Are Students Using Talk Moves?",
        id: "menu-sm-are-students",
        anchor: "stm",
      },
      {
        name: "Which Talk Moves are Students Using?",
        id: "menu-sm-which",
        anchor: "stm-which",
      },
      {
        name: "Are Students Using Each Category?",
        id: "menu-sm-category",
        anchor: "stm-category",
      },
      {
        name: "When are Students Using Talk Moves?",
        id: "menu-sm-when",
        anchor: "stm-when",
      },
    ],
    },
    
  {
    name: "Other Discourse Moves",
    color: "#00f",
    id: "menu-otm",
    anchor: "page-otm",
    menuItems: [
      {
        name: "How Much Did the Students Talk?",
        id: "menu-om-student-talk",
        anchor: "otm",
      },
      {
        name: "Did the Students Give More than One-word Answers?",
        id: "menu-om-one-word",
        anchor: "otm-one-word",
      },
      {
        name: "Did the Teacher Give Students Time to Think?",
        id: "menu-om-think",
        anchor: "otm-think",
      },
      {
        name: "Frequency of Math Terms",
        id: "menu-om-math-terms",
        anchor: "otm-math-terms",
      },
    ],
  },
];

export default class Video extends Component {
  constructor(props) {
    super();

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    // can't use this until we update to function component!
    // let { id } = useParams(); 
    //  const video_id = props.match && props.match.params && props.match.params.id ? props.match.params.id : sessionStorage.getItem("mostRecentVideo")
    const pathParts = window.location.pathname.split('/video/');
    const video_id = pathParts.length > 1 ? pathParts[1] : sessionStorage.getItem("mostRecentVideo");
    const isCoach = props.isCoach || props.isHATResearcher;

    this.state = {
      isLoading: true,
      video_id,
      // video_data: props.location.state.files,
      // video_name: props.location.state.name,
      // video_date: props.location.state.date,
      video_name: "",
      video_date: "",
      projectData: [],
      stats_data: [],
      topMenuSelected: null,
      subMenuSelected: null,
      hoverEl: false,
      hoverTip: false,
      isAuthenticated: props.isAuthenticated,
      demoData: false,
      isCoach,
      lessonLabel: props.lessonLabel,
    };
    
    const page_data = "latest-lesson";
    // eslint-disable-next-line no-undef
    gtag("event", "page_view", {
      page_title: page_data,
      page_location: `/${page_data}/${video_id}`,
      page_path: `/${page_data}/${video_id}`,
      send_to: "UA-91361306-3",
    });

    // Filtering by feature works great, but in the _teacher_ demo, we just want to display the full menu.
    // this.updateFeedbackMenu()
  }

  /**
   * Function to update the feedback menu using the features defined for a user.
   * This will sort the menu to match the order specified in the features. If features do not contain an item, it will be hidden in the menu.
   */
  updateFeedbackMenu(){
    let features = sessionStorage.getItem("features")
    if(features){
      try{
        features = JSON.parse(features)
        const {tutorial_feedback_menu} = features
        const temp = tutorial_feedback_menu.value
          //Check if the item exists in the lesson menu and only then proceed
          .filter(item => lessonMenu.findIndex(lesson => lesson.name == item) != -1)
          //Sort it to the position in the features
          .map(item => lessonMenu[lessonMenu.findIndex(lesson => lesson.name == item)])
        lessonMenu = temp
      }
      catch(err){
        console.error("Something went wrong when updating menu using features ", features, "\nError-", err)
      }
    }
    else{
      console.debug("No features configured for tutorial_feedback_menu. Using default settings")
    }
  }

  async refreshAllData() {
    try {
      const demoData = await this.getSystemData();
      const projectData = await this.getData(demoData); // this no longer relies on the teacher's Id/email... just the videoId

      const trendData = await this.getTrendData(demoData, projectData.teacher_id); // switched order so can use the teacher's id for the trend query
      if (projectData !== -1) {
        const stats_path = projectData["stats"];
        console.log('stats_path:', stats_path);
        const stats_data = await axios
          .get(stats_path)
          .then((data) => {
            return data;
          })
          .catch((err) => {
            return err;
          });

        if (stats_data) {
          sessionStorage.setItem("stats", JSON.stringify(stats_data.data));
        } else {
          sessionStorage.setItem("stats", null);
        }

        this.setState({
          video_id: projectData.video_id,
          projectData,
          standardizedAverages: trendData,
          normalize_factor: projectData.normalize_factor,
          normalize_message: projectData.normalize_message,
          stats_data: stats_data.data,
          isLoading: false,
        });

        this.recordPageAction( {        
          'eventName': 'tutorialFeedback',
          'projectData': projectData
        })
      } else {
        this.setState({ projectData, stats_data: -1, isLoading: false });
      }
    } catch (e) {
      // alert(e);
    }
  }
  async componentDidMount() {
    // if (!this.props.isAuthenticated) {
    //     return;
    // }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    await this.refreshAllData();
  }

  async componentDidUpdate(prevProps) {

    const pathParts = window.location.pathname.split('/video/');
    const videoToUse = pathParts.length > 1 ? pathParts[1] : sessionStorage.getItem("mostRecentVideo");
  
    if (this.props.isAuthenticated && (videoToUse !== this.state.video_id)) {
      this.setState({
        video_id: videoToUse,
        topMenuSelected: null,
        subMenuSelected: null, isLoading: true
      },
        async () => {
          await this.refreshAllData();
        })
    }
  }

  /* recordPageAction
   *    Publish an event to our MixPanel subscriber to track the user, their characteristics
   *    and details of their action.
   *
   *    Input:
   *       objArgs {
   *          'eventName' : String,
   *          'action' : String || null,
   *          'projectData' : { <project data struct> },
   *          'properties' : String || this.props
   *       }
   *    Output:
   *       None
   *    Side effects:
   *       publishes MixPanel event
   */
  recordPageAction(objArgs) {
    const properties = objArgs.properties || this.props;
    const projectData = objArgs.projectData || this.state.projectData;
    mixpanel.track(objArgs.eventName, {
      'action': objArgs.action || 'initialView',
      'subAction': this.state.subMenuSelected || 'none',
      'userAuthenticated': properties.isAuthenticated,
      'userIsCoach': properties.isCoach && true,
      'userIsResearcher': properties.isHATResearcher && true,
      'userId': sessionStorage.getItem('userId'),
      'userDistrict': sessionStorage.getItem('userDistrict'),      
      'tutorId': projectData.teacher_id,
      'tutorName': projectData.teacher_name,          
      'tutorial': projectData.video_id
    });
  }


  getSystemData = async () => {
    try {
      const settings = await API.get(`settings`, `settings`);
      this.setState({ demoData: settings.output.demoData });
      return settings.output.demoData;
    } catch (err) {
      console.log('settings error');
    }
    return false;
  }

  /*-------------
   * getData
   *
   *   If a user is authenticated this function returns the data for the 
   *   currently bound user (email address) and video. If a user is not
   *   authenticated, it returns the data for a hard-coded user video for
   *   demonstrations.
   *
   *   Calls endpoint: teachers/getvideo/{user_id}/{video_id}
   *
   *   Known Issue:
   *     The demonstration video is only available in the production environment
   */
    
  getData = async (demoData) => {

    const email = this.props.isAuthenticated || !demoData
      ? encodeURI(getCurrentUser())
      : encodeURI(demoData.teacher);
    //console.log(email);

    const video_id = this.props.isAuthenticated || !demoData
    ? this.state.video_id 
    : demoData.video;
    if (video_id) {
      const data = await API.get(
        `teachers`,
        `teachers/getvideo/${email}/${video_id}`
      );
      return data;
    }
    return -1;
  };

  /*-------------
   * getData
   *
   */
    
  getTrendData = async (demoData, teacherId) => {
    // const email = this.props.isAuthenticated
    //   ? encodeURI(getCurrentUser())
    //   : encodeURI(demoData.teacher);
    const email = teacherId;
    console.log('teacherId: ', email);
    try{
    const data = await API.get(
      "teachers",
      `teachers/getvideos/${email}/feedback`
    );

    const standardizedAverages = { teacher: {}, student: {} };
    // create standardized averages for frequencies
    Object.keys(data[0].tb_feedback.frequency.standardized.teacher).forEach(
      (key) => {
        let sum = 0;
        if (data.length > 0) {
          data.forEach((row) => {
            sum += row.tb_feedback.frequency.standardized.teacher[key];
          });
          standardizedAverages.teacher[key] = (sum / data.length).toFixed(1); //newValue.toFixed(decimals);
        } else {
          standardizedAverages.teacher[key] = 0;
        }
      }
    );
    Object.keys(data[0].tb_feedback.frequency.standardized.student).forEach(
      (key) => {
        let sum = 0;
        if (data.length > 0) {
          data.forEach((row) => {
            sum += row.tb_feedback.frequency.standardized.student[key];
          });
          standardizedAverages.student[key] = (sum / data.length).toFixed(1);
        } else {
          standardizedAverages.student[key] = 0;
        }
      }
    );
    return standardizedAverages;
    }
    catch(ex){
      console.log(ex)
    }
  };

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  scrollTo = (id) => {
    const scrollToElement = document.getElementById(id);
    if (scrollToElement) {
      scrollToElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  selectTopLevelNav(id, anchor, name) {
    this.setState({ topMenuSelected: id, subMenuSelected: false });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    const page_data = `current-lesson`;
    const path = name.toLowerCase().replace(" ", "-");
    // eslint-disable-next-line no-undef
    gtag("event", "page_view", {
      page_title: `${page_data}-${name.replace(" ", "-")}`,
      page_location: `/${page_data}/${this.state.video_id}/${path}`,
      page_path: `/${page_data}/${this.state.video_id}/${path}`,
      send_to: "UA-91361306-3",
    });
  }

  selectMenuItem(id, anchor, name) {
    this.setState({ subMenuSelected: id });
    this.scrollTo(anchor);

    trackEvent(`Current tutorial: ${name}`, { videoId: this.state.video_id });
  }

  showNormalizedValue(value) {
    const { normalize_factor } = this.state;
    return Math.round(value * normalize_factor * 10) / 10;
  }

  closeHoverTip = () => {
    this.setState({ hoverEl: false, hoverTip: false });
  };

  onHoverTip = (e, tip, wide = false) => {
    const event = e;
    this.setState({
      hoverEl: e,
      pageY: e.pageY,
      pageX: e.pageX,
      hoverWide: wide,
      hoverTip: tip,
    });
  };

  renderLeftNav() {
    const { topMenuSelected, subMenuSelected, isAuthenticated } = this.state;
    const { isAdmin, isHATResearcher } = this.props;
    //debugger;

    return (
      <div className="video-fixed-nav">
        {lessonMenu.map((topMenu) => (
            <>
            {(!topMenu.restrictedTo || isAdmin || isHATResearcher) && (
            <> 
            <h4
              id={topMenu.id}
              className={`video-topmenu ${
                topMenuSelected === topMenu.id ? "active" : ""
              }`}
              onClick={() =>
                this.selectTopLevelNav(topMenu.id, topMenu.anchor, topMenu.name)
              }
            >
              {/* <span
                                style={{
                                    fontWeight: `${topMenuSelected === topMenu.id ? 600 : 400}`,
                                    color: `${topMenuSelected === topMenu.id ? '#17a2b8' : '#474B4E'}`,
                                    cursor: 'pointer',
                                }}
                            > */}
              {topMenu.name}
              {/* </span> */}
            </h4>
            {topMenu.menuItems &&
              topMenuSelected === topMenu.id &&
              topMenu.menuItems.map((item) => (
                <>
                  <div
                    id={item.id}
                    className={`video-menuitem ${
                      subMenuSelected === item.id ? "active" : ""
                    }`}
                    onClick={() =>
                      this.selectMenuItem(item.id, item.anchor, item.name)
                    }
                  >
                    {item.name}
                    {/* </span> */}
                  </div>
                </>
              ))}
            </>
            )}
          </>          
        ))}
            {/* add in all videos link */}
            {/* We no longer want this -- 16Mar2023 - Dooley 
            {isAuthenticated && (
                <h4
                    className={`video-topmenu `}
                    onClick={() => {
                        this.props.history("/videos");
                    }}
                >
                    All {this.props.lessonLabel}s
                </h4>
        )} */}

      </div>
    );
  }

  renderViewTitle() {
    const { isCoach, lessonLabel, projectData } = this.state;
    const videoDate = normalizeProjectTimeStamp(projectData.video_date);
    if (isCoach) {
      return (
        <>
        {lessonLabel}:&nbsp;
        <span>
          {this.state.isLoading === false &&
            this.state.stats_data !== -1 && (
              <span>
                    {`${projectData.video_name}`}
                    <br/>                    
                    Teacher: {`${projectData.teacher_name}`}
                    <br/>
                    Date: {`${videoDate} (id: ${projectData.video_id})`}
              </span>
            )
            }
        </span>
        </>)
      } 
      else {
        return (
          <>
          {lessonLabel}:&nbsp;
          <span>
            {this.state.isLoading === false &&
             this.state.stats_data !== -1 && (
              <span>
                  {`${projectData.video_name}`}
                  <br />Date: &nbsp; {`${videoDate}`}
              </span>
              )
            }
          </span>        
        </>)

        }
  }

  
  renderFeedbackButtons() {
    debugger;
    if (this.state.isLoading) {
      return null;
    }
    return (
      <Row className="lesson-panel-row">
        {/* <Col sm={4} style={{textAlign: 'center'}}><Button size='md' className="lesson-CTA-button" onClick={() => this.selectTopLevelNav('menu-ttm', 'lca')}><div className="lesson-CTA">Feedback on Teacher Talk Moves</div> Talk Moves</Button></Col>
            <Col sm={4} style={{textAlign: 'center'}}><Button size='md' className="lesson-CTA-button" onClick={() => this.selectTopLevelNav('menu-stm', 'lca')}><div className="lesson-CTA">Student</div> Talk Moves</Button></Col>
            <Col sm={4} style={{textAlign: 'center'}}><Button size='md' className="lesson-CTA-button" onClick={() => this.selectTopLevelNav('menu-otm', 'lca')}><div className="lesson-CTA">Other</div> Discourse</Button></Col> */}

          <Col sm={4} style={{ textAlign: "left" }}>
          <div
            className="lesson-CTA-link"
            onClick={() =>
              this.selectTopLevelNav("menu-ttm", "lca", "Teacher Talk Moves")
            }
          >
            Teacher Talk Moves
          </div>
          <br/>
          <div
            className="lesson-CTA-link"
            onClick={() =>
              this.selectTopLevelNav("menu-otm", "lca", "Other Discourse Moves")
            }
          >
            Other Discourse Moves
          </div>

        </Col>
        <Col sm={2} style={{ textAlign: "center" }}>
          <div
            className="lesson-CTA-link"
            onClick={() =>
              this.selectTopLevelNav("menu-stm", "lca", "Student Talk Moves")
            }
          >
            Student Talk Moves
          </div>
        </Col>

      </Row>
    );
  }
    renderTopPanel() {
      const { isAuthenticated, isLoading } = this.state;

      if (isLoading) {
        return (
          <div style={{width: '85%', paddingTop: 200,  }}>
            <LoadingIndicator message={`Loading ${this.props.lessonLabel}`} />
          </div>
        )
      }

      return (
        <div id="this-lesson" className="video-lesson">
          <Row className="lesson-panel-toprow">
            <h4 style={{ width: "100%", textAlign: "left", marginBottom: 36 }}>
              {isAuthenticated
                ?
                <>
                  {this.renderViewTitle()}
                </>
                : <span>Sample Lesson from {this.state.demoData.date}</span>
              }
            </h4>
        </Row>
        <Row className="lesson-panel-row">
          <Col sm={6}>
            { isAuthenticated 
            ?
            <>
             <video
              width={"90%"}
              height={"auto"}
              controls
              style={{ border: "1px solid lightblue" }}
            >
              {this.state.isLoading === false && (
                <source src={this.state.projectData["video"]} type="audio/mpeg"/>
              )} 
              </video>
            <div style={{ width: "90%", textAlign: "center", margin: 0 }}>
              {this.props.lessonLabel} Video
            </div>
            </>
            :
            <div 
                style={{
                    width: '90%',
                    backgroundImage: `url(${SampleVideoImage})`,
                    backgroundSize: 'contain',
                    height: 300,
                    backgroundRepeat: 'no-repeat',
                    padding: 24,
                    fontSize: 22,
                    color: '#111',
                    textAlign: 'center'
                }}>
                    This sample lesson, recorded in 2020<br />
                    is from a 5th grade math class
                
            </div>
            }
          </Col>
          <Col sm={6}>
            <div
              style={{
                width: "100%",
                textAlign: "center",
                border: "1px solid lightblue",
              }}
            >
              {this.state.isLoading === false && (
                <img
                  title="This graphic shows the words used most frequently by the teacher and students in the lesson. More frequently used words are shown in a bigger font. The color of the words has no special meaning."
                  alt="word cloud"
                  style={{ width: "100%" }}
                  onClick={this.handleShow}
                  src={this.state.projectData["word_cloud"]}
                />
                // <Card.Img variant="top" onClick={this.handleShow} src={this.state.projectData['word_cloud']} />
              )}
            </div>
            <div style={{ width: "100%", textAlign: "center", margin: 0 }}>
              Words Used by Frequency
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  calculateBenchmark(value, decimals) {
    const tb_stats = this.state.stats_data.tb_stats.teacher;
    const benchmarkClasses = tb_stats.number_of_transcripts;
    const benchmarkMinutes = 2186; // hard code for now.
    const standardMinPerClass = 55;
    const factor = (benchmarkClasses * standardMinPerClass) / benchmarkMinutes;
    const newValue = value * factor;
    if (decimals === 2) {
    }

    return newValue.toFixed(decimals);
  }

  renderTeacherPanel() {
    const frequency = this.state.stats_data.tb_feedback.frequency.teacher;
    const category = this.state.stats_data.tb_feedback.category.teacher;
    const tb_stats = this.state.stats_data.tb_stats.teacher;
    const sStats = this.state.standardizedAverages;
    const {isCoach} = this.state;

    this.recordPageAction( {
      'eventName': 'tutorialFeedback',      
      'action': 'tutorTalkMoves',
      'projectData': this.state.projectData
    })

    return (
      <div id="ttm" className="video-lesson  lesson-feedback">
        {this.state.isLoading === false && (
          <>
            <Row className="lesson-panel-toprow">
              <h4
                style={{ width: "100%", textAlign: "left", marginBottom: 12 }}
              >
                {this.state.isAuthenticated
                ?
                <>
                    {this.renderViewTitle()}
                </>
                 : <span>Sample Lesson from January, 2020</span>
                }
              </h4>
            </Row>
            <Row id="ttm-am-i" className="anchor-margin">
              {" "}
              {/* ********************** talk moves used ***************** */}
              <div className="col-12">
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 10,
                    marginTop: 34,
                  }}
                >
                  <h4>
                    <b>Did the Teacher Use Talk Moves?</b>
                  </h4>
                  <b>
                    {this.state.isLoading === false &&
                      frequency.total_teacher_talkmoves}
                  </b>{" "}
                  Talk Moves used in this session
                </div>

                <div style={{ width: "100%" }}>
                  <div style={{ width: "80%", display: "inline-block" }}>
                    <SimpleBarChart
                      stats_data={this.state.stats_data}
                      video_id={this.state.video_id}
                      history={this.props.history}
                    />
                    <div style={{ textAlign: "center", color: "black" }}>
                      Number of Talk Moves Used
                    </div>
                  </div>
                  <div
                    style={{
                      verticalAlign: "top",
                      width: "20%",
                      display: "inline-block",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        maxWidth: 116,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#f6cc46",
                        textAlign: "center",
                        marginBottom: 8,
                      }}
                    >
                      Learning Community
                    </div>
                    <div
                      style={{
                        width: "90%",
                        maxWidth: 116,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#9ee4e1",
                        textAlign: "center",
                        marginBottom: 8,
                      }}
                    >
                      Content Knowledge
                    </div>
                    <div
                      style={{
                        width: "90%",
                        maxWidth: 116,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#ff7359",
                        textAlign: "center",
                        marginBottom: 0,
                      }}
                    >
                      Rigorous Thinking
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Row id="ttm-which" className="anchor-margin">
              {" "}
              {/* ******************** which did I use ****************** */}
              <div
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 32,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>Which Talk Moves Did the Teacher Use?</b>
                  </h4>
                </div>
                <table
                  className="table table-borderless"
                  style={{ marginBottom: "20px" }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#dddddd" }}>
                      <th scope="col">Talk Move</th>
                      <th
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "Total # of teacher talk moves by category in this lesson"
                          )
                        }
                        scope="col"
                      >
                        Total #
                      </th>
                      <th
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            'The "normalized" number of Talk Moves by category (as if this lesson were 55 minutes)'
                          )
                        }
                        scope="col"
                        style={{ width: "25%" }}
                      >
                        Total # (Standardized*)
                      </th>
                      <th
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "Average talk moves (standardized)"
                          )
                        }
                        scope="col"
                      >
                        Average (Standardized)
                      </th>
                      {!isCoach &&
                      <th
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "Average total number of talk moves by category for all teachers in our data set"
                          )
                        }
                        scope="col"
                      >
                        All Teachers Average
                        <br />
                        (Standardized)
                      </th>
			                }
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ backgroundColor: "#fae5a2" }}>
                      <td
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "This teacher talk move refers to prompts that help ensure students are contributing to the conversation as well as actively listening.",
                            true
                          )
                        }
                      >
                        Keep together
                      </td>
                      <td>{frequency["keeping_everyone_together"]}</td>
                      <td>
                        <i>
                          {this.showNormalizedValue(
                            frequency.keeping_everyone_together
                          )}
                        </i>
                      </td>
                      {/* <td><i>{this.calculateBenchmark(tb_stats.average_keeping_everyone_together,0)}</i></td> */}
                      <td>
                        <i>{sStats.teacher.keeping_everyone_together}</i>
                      </td>
                			{!isCoach &&                      
                      <td>
                        <i>{benchmarkData.keeptogether}</i>
                      </td>
                      }
                    </tr>
                    <tr style={{ backgroundColor: "#fae5a2" }}>
                      <td
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "This teacher talk move refers to prompts that invite students to respond to another student’s ideas.",
                            true
                          )
                        }
                      >
                        Students Relate
                      </td>
                      <td>{frequency["getting_students_to_relate"]}</td>
                      <td>
                        <i>
                          {this.showNormalizedValue(
                            frequency.getting_students_to_relate
                          )}
                        </i>
                      </td>
                      {/* <td><i>{this.calculateBenchmark(tb_stats.average_getting_students_to_relate,0)}</i></td> */}
                      <td>
                        <i>{sStats.teacher.getting_students_to_relate}</i>
                      </td>
                			{!isCoach &&                      
                      <td>
                        <i>{benchmarkData.studentsrelate}</i>
                      </td>
                      }
                    </tr>
                    <tr style={{ backgroundColor: "#fae5a2" }}>
                      <td
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "This teacher talk move refers to teacher statements that exactly repeat what a student said.",
                            true
                          )
                        }
                      >
                        Restate
                      </td>
                      <td>{frequency["restating"]}</td>
                      <td>
                        <i>{this.showNormalizedValue(frequency.restating)}</i>
                      </td>
                      {/* <td><i>{this.calculateBenchmark(tb_stats.average_restating,0)}</i></td> */}
                      <td>
                        <i>{sStats.teacher.restating}</i>
                      </td>
                			{!isCoach &&                      
                      <td>
                        <i>{benchmarkData.restate}</i>
                      </td>
                      }
                    </tr>
                    <tr style={{ backgroundColor: "#9ee4e1" }}>
                      <td
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "This teacher talk move refers to prompts that invite students to contribute mathematical information.",
                            true
                          )
                        }
                      >
                        Accuracy
                      </td>
                      <td>{frequency["press_for_accuracy"]}</td>
                      <td>
                        <i>
                          {this.showNormalizedValue(
                            frequency.press_for_accuracy
                          )}
                        </i>
                      </td>
                      {/* <td><i>{this.calculateBenchmark(tb_stats.average_press_for_accuracy,0)}</i></td> */}
                      <td>
                        <i>{sStats.teacher.press_for_accuracy}</i>
                      </td> 
                			{!isCoach &&                     
                      <td>
                        <i>{benchmarkData.accuracy}</i>
                      </td>
                      }
                    </tr>
                    <tr style={{ backgroundColor: "#ffb9ac" }}>
                      <td
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "This teacher talk move refers to statements that reword what a student said.",
                            true
                          )
                        }
                      >
                        Revoice
                      </td>
                      <td>{frequency["revoicing"]}</td>
                      <td>
                        <i>{this.showNormalizedValue(frequency.revoicing)}</i>
                      </td>
                      {/* <td><i>{this.calculateBenchmark(tb_stats.average_revoicing,0)}</i></td> */}
                      <td>
                        <i>{sStats.teacher.revoicing}</i>
                      </td>
                			{!isCoach &&                      
                      <td>
                        <i>{benchmarkData.revoice}</i>
                      </td>
                      }
                    </tr>
                    <tr style={{ backgroundColor: "#ffb9ac" }}>
                      <td
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "This teacher talk move refers to prompts that invite students to provide an explanation, construct an argument, and engage in mathematical reasoning.",
                            true
                          )
                        }
                      >
                        Reasoning
                      </td>
                      <td>{frequency["press_for_reasoning"]}</td>
                      <td>
                        <i>
                          {this.showNormalizedValue(
                            frequency.press_for_reasoning
                          )}
                        </i>
                      </td>
                      {/* <td><i>{this.calculateBenchmark(tb_stats.average_press_for_reasoning,0)}</i></td> */}
                      <td>
                        <i>{sStats.teacher.press_for_reasoning}</i>
                      </td>
                			{!isCoach &&                      
                      <td>
                        <i>{benchmarkData.reasoning}</i>
                      </td>
                      }
                    </tr>
                  </tbody>
                </table>
                <div
                  style={{
                    fontStyle: "italic",
                    fontSize: 12,
                    width: "80%",
                    margin: "auto",
                    textAlign: "center",
                  }}
                >
                  {`* ${this.state.normalize_message}`}
                </div>
              </div>
            </Row>
            <Row id="ttm-category" className="anchor-margin">
              {" "}
              {/* ******************** am I using each category ****************** */}
              <div
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 32,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>Did the Teacher Use Talk Moves from Each Category?</b>
                  </h4>
                </div>

                <div
                  style={{
                    width: "60%",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  <TalkmovesPieChart
                    stats_data={this.state.stats_data}
                    video_id={this.state.video_id}
                  />
                </div>
                <div
                  style={{
                    verticalAlign: "top",
                    width: "40%",
                    maxWidth: 116,
                    marginTop: 20,
                    display: "inline-block",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      padding: 8,
                      border: "1px solid gray",
                      borderRadius: "5px",
                      backgroundColor: "#f6cc46",
                      textAlign: "center",
                      marginBottom: 8,
                    }}
                  >
                    Learning Community
                  </div>
                  <div
                    style={{
                      width: "90%",
                      padding: 8,
                      border: "1px solid gray",
                      borderRadius: "5px",
                      backgroundColor: "#9ee4e1",
                      textAlign: "center",
                      marginBottom: 8,
                    }}
                  >
                    Content Knowledge
                  </div>
                  <div
                    style={{
                      width: "90%",
                      padding: 8,
                      border: "1px solid gray",
                      borderRadius: "5px",
                      backgroundColor: "#ff7359",
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    Rigorous Thinking
                  </div>
                </div>

                <table
                  className="table table-borderless"
                  style={{ marginTop: 0 }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#dddddd" }}>
                      <th scope="col">Category</th>
                      <th scope="col" title="placehold here">
                        Percent
                      </th>
                      {!isCoach &&                                            
                      <th
                        scope="col"
                        title="placehold here"
                        style={{ width: "30%" }}
                      >
                        All Teachers Percent
                      </th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ backgroundColor: "#fae5a2" }}>
                      <td>
                        <u
                          onMouseOver={(e) =>
                            this.onHoverTip(
                              e,
                              "This category refers to the teacher's facilitation of an equitable classroom environment in which all students are invited to participate and to stay on the same page."
                            )
                          }
                        >
                          <b>Learning Community</b>
                        </u>{" "}
                        <br />
                        <span style={{ fontSize: 12, fontStyle: "italic" }}>
                          Keeping everyone together
                          <br />
                          Getting students to relate
                          <br />
                          Restating
                        </span>
                      </td>
                      <td>
                        {this.state.isLoading === false &&
                          Math.round(category.learning_community_percent)}
                        %
                      </td>
                      {!isCoach &&                                                                                                              
                      <td>
                        <i>{tb_stats.average_learning_community_percent}%</i>
                      </td>
                      }
                    </tr>
                    <tr style={{ backgroundColor: "#9ee4e1" }}>
                      <td>
                        <u
                          onMouseOver={(e) =>
                            this.onHoverTip(
                              e,
                              "This category refers to the teacher's press for students to provide the mathematical content."
                            )
                          }
                        >
                          <b>Content Knowledge</b>
                        </u>{" "}
                        <br />
                        <span style={{ fontSize: 12, fontStyle: "italic" }}>
                          Accuracy
                        </span>
                      </td>
                      <td>
                        {this.state.isLoading === false &&
                          Math.round(category.content_knowledge_percent)}
                        %
                      </td>
                      {!isCoach &&                                                                                                              
                      <td>
                        <i>{tb_stats.average_content_knowledge_percent}%</i>
                      </td>
                      }
                    </tr>
                    <tr style={{ backgroundColor: "#ffb9ac" }}>
                      <td>
                        <u
                          onMouseOver={(e) =>
                            this.onHoverTip(
                              e,
                              "This category refers to the teacher’s efforts to position students as the holders of ideas and to encourage them to share their reasoning."
                            )
                          }
                        >
                          <b>Rigorous Thinking</b>
                        </u>{" "}
                        <br />
                        <span style={{ fontSize: 12, fontStyle: "italic" }}>
                          Revoicing
                          <br />
                          Reasoning
                        </span>
                      </td>
                      <td>
                        {this.state.isLoading === false &&
                          Math.round(category.rigorous_thinking_percent)}
                        %
                      </td>
                      {!isCoach &&                                                                                        
                      <td>
                        <i>{tb_stats.average_rigorous_thinking_percent}%</i>
                      </td>
                      }
                    </tr>
                  </tbody>
                </table>
              </div>
            </Row>
            <Row id="ttm-when" className="anchor-margin">
              {" "}
              {/* ******************** When Am I Using them ****************** */}
              <div
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 32,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>When Did the Teacher Use Talk Moves?</b>
                  </h4>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "80%",
                      display: "inline-block",
                      textAlign: "center",
                    }}
                  >
                    <StackedBarChart
                      stats_data={this.state.stats_data}
                      video_id={this.state.video_id}
                    />
                  </div>
                  <div
                    style={{
                      verticalAlign: "top",
                      width: "20%",
                      display: "inline-block",
                      paddingLeft: 20,
                    }}
                  >
                    <div
                      style={{
                        width: "116px",
                        padding: 8,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#f6cc46",
                        textAlign: "center",
                        marginBottom: 8,
                      }}
                    >
                      Learning Community
                    </div>
                    <div
                      style={{
                        width: "116px",
                        padding: 8,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#9ee4e1",
                        textAlign: "center",
                        marginBottom: 8,
                      }}
                    >
                      Content Knowledge
                    </div>
                    <div
                      style={{
                        width: "116px",
                        padding: 8,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#ff7359",
                        textAlign: "center",
                        marginBottom: 0,
                      }}
                    >
                      Rigorous Thinking
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}
      </div>
    );
  }

  renderStudentPanel() {
    const frequency = this.state.stats_data.tb_feedback.frequency.student;
    const category = this.state.stats_data.tb_feedback.category.student;
    const tb_stats = this.state.stats_data.tb_stats.student;
    const sStats = this.state.standardizedAverages;

    this.recordPageAction( {
      'eventName': 'tutorialFeedback',      
      'action': 'studentTalkMoves',
      'projectData': this.state.projectData
    })

    return (
      <div id="stm" className="video-lesson  lesson-feedback">
        {this.state.isLoading === false && (
          <>
              <Row className="lesson-panel-toprow">

              <h4
                style={{ width: "100%", textAlign: "left", marginBottom: 12 }}
              >
                {this.state.isAuthenticated
                ?
                <>
                  {this.renderViewTitle()}
                </>
                 : <span>Sample Lesson from January, 2020</span>
                }
              </h4>
            </Row>
            <Row id="stm-am-i">
              {" "}
              {/* ********************** talk moves used ***************** */}
              <div className="col-12">
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 10,
                    marginTop: 34,
                  }}
                >
                  <h4>
                    <b>Are the Students Using Talk Moves?</b>
                  </h4>
                  Students used{" "}
                  <b>
                    {this.state.isLoading === false &&
                      frequency.total_student_talkmoves}
                  </b>{" "}
                  Talk Moves in this lesson
                </div>

                <div style={{ width: "100%" }}>
                  <div style={{ width: "80%", display: "inline-block" }}>
                    <SimpleBarChart
                      student
                      stats_data={this.state.stats_data}
                      video_id={this.state.video_id}
                      history={this.props.history}
                    />
                    <div style={{ textAlign: "center", color: "black" }}>
                      Number of Talk Moves Used
                    </div>
                  </div>
                  <div
                    style={{
                      verticalAlign: "top",
                      width: "20%",
                      display: "inline-block",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        maxWidth: 116,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#f6cc46",
                        textAlign: "center",
                        marginBottom: 8,
                      }}
                    >
                      Learning Community
                    </div>
                    <div
                      style={{
                        width: "90%",
                        maxWidth: 116,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#9ee4e1",
                        textAlign: "center",
                        marginBottom: 8,
                      }}
                    >
                      Content Knowledge
                    </div>
                    <div
                      style={{
                        width: "90%",
                        maxWidth: 116,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#ff7359",
                        textAlign: "center",
                        marginBottom: 0,
                      }}
                    >
                      Rigorous Thinking
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Row id="stm-which">
              {" "}
              {/* ******************** which did I use ****************** */}
              <div
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 32,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>Which Talk Moves Did the Students Use?</b>
                  </h4>
                </div>
                <table
                  className="table table-borderless"
                  style={{ marginBottom: "20px" }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#dddddd" }}>
                      <th scope="col">Talk Move</th>
                      <th
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "Total # of student talk moves by category in this lesson"
                          )
                        }
                        scope="col"
                      >
                        Total #
                      </th>
                      <th
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            `The "normalized" number of talk moves by category (as if this lesson were 55 minutes)`
                          )
                        }
                        scope="col"
                        style={{ width: "25%" }}
                      >
                        Total # (Standardized*)
                      </th>
                      <th
                        onMouseOver={(e) =>
                          this.onHoverTip(e, "Average (standardized)")
                        }
                        scope="col"
                      >
                        Average (Standardized)
                      </th>
                      <th
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "Average total number of talk moves by category for all students in our data set"
                          )
                        }
                        scope="col"
                      >
                        All Teachers Average
                        <br />
                        (Standardized)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ backgroundColor: "#f6cc46" }}>
                      <td
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "This student talk move refers to prompts that help ensure students are contributing to the conversation as well as actively listening.",
                            true
                          )
                        }
                      >
                        Asking For More Information
                      </td>
                      <td>{frequency.asking_for_more_information}</td>
                      <td>
                        <i>
                          {this.showNormalizedValue(
                            frequency.asking_for_more_information
                          )}
                        </i>
                      </td>
                      {/* <td><i>{this.calculateBenchmark(tb_stats.average_asking_for_more_information,0)}</i></td> */}
                      <td>
                        <i>{sStats.student.asking_for_more_information}</i>
                      </td>
                      <td>
                        <i>{benchmarkData.moreinfo}</i>
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#f6cc46" }}>
                      <td
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "This student talk move refers to prompts that invite students to contribute mathematical information.",
                            true
                          )
                        }
                      >
                        Relating to Another Student
                      </td>
                      <td>{frequency.relating_to_another_student}</td>
                      <td>
                        <i>
                          {this.showNormalizedValue(
                            frequency.relating_to_another_student
                          )}
                        </i>
                      </td>
                      {/* <td><i>{this.calculateBenchmark(tb_stats.average_relating_to_another_student,0)}</i></td> */}
                      <td>
                        <i>{sStats.student.relating_to_another_student}</i>
                      </td>
                      <td>
                        <i>{benchmarkData.relatetoanother}</i>
                      </td>
                    </tr>

                    <tr style={{ backgroundColor: "#9ee4e1" }}>
                      <td
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "This student talk move refers to prompts that invite students to respond to another student’s ideas.",
                            true
                          )
                        }
                      >
                        Making a Claim
                      </td>
                      <td>{frequency.making_a_claim}</td>
                      <td>
                        <i>
                          {this.showNormalizedValue(frequency.making_a_claim)}
                        </i>
                      </td>
                      {/* <td><i>{this.calculateBenchmark(tb_stats.average_making_a_claim, 0)}</i></td> */}
                      <td>
                        <i>{sStats.student.making_a_claim}</i>
                      </td>
                      <td>
                        <i>{benchmarkData.makeclaim}</i>
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#ff7359" }}>
                      <td
                        onMouseOver={(e) =>
                          this.onHoverTip(
                            e,
                            "This student talk move refers to teacher statements that exactly repeat what a student said.",
                            true
                          )
                        }
                      >
                        Providing Reasoning/Evidence
                      </td>
                      <td>{frequency.providing_evidence}</td>
                      <td>
                        <i>
                          {this.showNormalizedValue(
                            frequency.providing_evidence
                          )}
                        </i>
                      </td>
                      {/* <td><i>{this.calculateBenchmark(tb_stats.average_providing_evidence,0)}</i></td> */}
                      <td>
                        <i>{sStats.student.providing_evidence}</i>
                      </td>
                      <td>
                        <i>{benchmarkData.providereasoning}</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  style={{
                    fontStyle: "italic",
                    fontSize: 12,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  *{this.state.normalize_message}
                </div>
              </div>
            </Row>
            <Row id="stm-category">
              {" "}
              <div
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 32,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>Are the Students Using Each Category?</b>
                  </h4>
                </div>

                <div
                  style={{
                    width: "60%",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  <TalkmovesPieChart
                    students
                    stats_data={this.state.stats_data}
                    video_id={this.state.video_id}
                  />
                </div>
                <div
                  style={{
                    verticalAlign: "top",
                    width: "40%",
                    maxWidth: 116,
                    marginTop: 20,
                    display: "inline-block",
                  }}
                >
                  <div
                    style={{
                      width: "90%",
                      padding: 8,
                      border: "1px solid gray",
                      borderRadius: "5px",
                      backgroundColor: "#f6cc46",
                      textAlign: "center",
                      marginBottom: 8,
                    }}
                  >
                    Learning Community
                  </div>
                  <div
                    style={{
                      width: "90%",
                      padding: 8,
                      border: "1px solid gray",
                      borderRadius: "5px",
                      backgroundColor: "#9ee4e1",
                      textAlign: "center",
                      marginBottom: 8,
                    }}
                  >
                    Content Knowledge
                  </div>
                  <div
                    style={{
                      width: "90%",
                      padding: 8,
                      border: "1px solid gray",
                      borderRadius: "5px",
                      backgroundColor: "#ff7359",
                      textAlign: "center",
                      marginBottom: 0,
                    }}
                  >
                    Rigorous Thinking
                  </div>
                </div>

                <table
                  className="table table-borderless"
                  style={{ marginTop: 0 }}
                >
                  <thead>
                    <tr style={{ backgroundColor: "#dddddd" }}>
                      <th scope="col">Category</th>
                      <th scope="col" title="placehold here">
                        Percent
                      </th>
                      <th
                        scope="col"
                        title="placehold here"
                        style={{ width: "30%" }}
                      >
                        All Teachers Percent
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ backgroundColor: "#fae5a2" }}>
                      <td>
                        <u title="This category refers to the teacher's facilitation of an equitable classroom environment in which all students are invited to participate and to stay on the same page.">
                          <b>Learning Community</b>
                        </u>{" "}
                        <br />
                        <span style={{ fontSize: 12, fontStyle: "italic" }}>
                          Asking for More Information
                          <br />
                          Relating to Another Student
                          <br />
                        </span>
                      </td>
                      <td>
                        {this.state.isLoading === false &&
                          Math.round(category.learning_community_percent)}
                        %
                      </td>
                      <td>
                        <i>{tb_stats.average_learning_community_percent}%</i>
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#9ee4e1" }}>
                      <td>
                        <u title="This category refers to the teacher's press for students to provide the mathematical content.">
                          <b>Content Knowledge</b>
                        </u>{" "}
                        <br />
                        <span style={{ fontSize: 12, fontStyle: "italic" }}>
                          Making a Claim
                        </span>
                      </td>
                      <td>
                        {this.state.isLoading === false &&
                          Math.round(category.content_knowledge_percent)}
                        %
                      </td>
                      <td>
                        <i>{tb_stats.average_content_knowledge_percent}%</i>
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#ffb9ac" }}>
                      <td>
                        <u title="This category refers to the teacher’s efforts to position students as the holders of ideas and to encourage them to share their reasoning.">
                          <b>Rigorous Thinking</b>
                        </u>{" "}
                        <br />
                        <span style={{ fontSize: 12, fontStyle: "italic" }}>
                          Providing Reasoning/Evidence
                          <br />
                        </span>
                      </td>
                      <td>
                        {this.state.isLoading === false &&
                          Math.round(category.rigorous_thinking_percent)}
                        %
                      </td>
                      <td>
                        <i>{tb_stats.average_rigorous_thinking_percent}%</i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Row>
            <Row id="stm-when">
              {" "}
              {/* ******************** When Are the Students Using them ****************** */}
              <div
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 32,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>When Are the Students Using Talk Moves?</b>
                  </h4>
                </div>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "80%",
                      display: "inline-block",
                      textAlign: "center",
                    }}
                  >
                    <StackedBarChart
                      students
                      stats_data={this.state.stats_data}
                      video_id={this.state.video_id}
                    />
                  </div>
                  <div
                    style={{
                      verticalAlign: "top",
                      width: "20%",
                      display: "inline-block",
                      paddingLeft: 20,
                    }}
                  >
                    <div
                      style={{
                        width: "116px",
                        padding: 8,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#f6cc46",
                        textAlign: "center",
                        marginBottom: 8,
                      }}
                    >
                      Learning Community
                    </div>
                    <div
                      style={{
                        width: "116px",
                        padding: 8,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#9ee4e1",
                        textAlign: "center",
                        marginBottom: 8,
                      }}
                    >
                      Content Knowledge
                    </div>
                    <div
                      style={{
                        width: "116px",
                        padding: 8,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "#ff7359",
                        textAlign: "center",
                        marginBottom: 0,
                      }}
                    >
                      Rigorous Thinking
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}
      </div>
    );
  }

  testMathTerms = (terms, type) => {
    const list = terms[type];
    let count = list.most_frequent_1 !== "" ? 1 : 0;
    count += list.most_frequent_2 !== "" ? 1 : 0;
    count += list.most_frequent_3 !== "" ? 1 : 0;
    return count;
  };
  renderOtherPanel() {
    const { stats_data } = this.state;
    const { math_terms } = stats_data.tb_feedback;
    const studentTermsCount = this.testMathTerms(math_terms, "student");
    const teacherTermsCount = this.testMathTerms(math_terms, "teacher");

    this.recordPageAction( {
      'eventName': 'tutorialFeedback',
      'action': 'otherDiscourseMoves',
      'projectData': this.state.projectData
    })

    return (
      <div id="otm" className="video-lesson lesson-feedback">
        {this.state.isLoading === false && (
          <>
            <Row className="lesson-panel-toprow">
		  
              <h4
                style={{ width: "100%", textAlign: "left", marginBottom: 12 }}
              >
                {this.state.isAuthenticated
                ?
                <>
                  {this.renderViewTitle()}
            </>
             : <span>Sample Lesson from January, 2020</span>
            }
              </h4>
            </Row>
            <Row id="otm-student-talk">
              {" "}
              {/* ********************** amount of student talk ***************** */}
              <div className="col-12">
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 10,
                    marginTop: 34,
                  }}
                >
                  <h4>
                    <b>How Much Did the Students Talk?</b>
                  </h4>
                </div>

                <div style={{ width: "100%" }}>
                  <div style={{ width: "70%", display: "inline-block" }}>
                    <TeacherStudentPieChart
                      stats_data={stats_data}
                      video_id={this.state.video_id}
                    />
                  </div>
                  <div
                    style={{
                      verticalAlign: "middle",
                      width: "30%",
                      display: "inline-block",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                        maxWidth: 116,
                        color: "white",
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "rgb(132, 89, 152)",
                        textAlign: "center",
                        marginBottom: 24,
                      }}
                    >
                      % Students Talking
                    </div>
                    <div
                      style={{
                        width: "90%",
                        maxWidth: 116,
                        border: "1px solid gray",
                        borderRadius: "5px",
                        backgroundColor: "rgb(193, 150, 214)",
                        textAlign: "center",
                        marginBottom: 8,
                      }}
                    >
                      % Teacher Talking
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Row id="otm-one-word">
              {" "}
              {/* ******************** More than One-Word Answers ****************** */}
              <div
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 32,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>Did the Students Give More Than One-word Answers?</b>
                  </h4>
                </div>
                <div
                  style={{ fontSize: "60px", fontFamily: "Roboto, san-serif" }}
                >
                  {this.state.isLoading === false &&
                    Math.round(
                      stats_data.tb_feedback.utterances
                        .student_more_one_word_percent
                    )}
                  %
                </div>
                % of student sentences with more than one word
              </div>
            </Row>
            <Row id="otm-think">
              {" "}
              {/* ******************** Time to Think ****************** */}
              <div
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 32,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>Did the Teacher Give Students Time to Think?</b>
                  </h4>
                </div>
                <div
                  style={{ fontSize: "60px", fontFamily: "Roboto, san-serif" }}
                >
                  {this.state.isLoading === false &&
                    Math.round(
                      stats_data.tb_feedback.utterances
                        .teacher_wait_time_percent
                    )}
                  %
                </div>
                % of teacher sentences with &#8805; 3 seconds wait time
              </div>
            </Row>
            <Row id="otm-math-terms">
              {" "}
              {/* ******************** Frequency of Math Terms ****************** */}
              <div
                style={{ width: "80%", margin: "auto", textAlign: "center" }}
              >
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: 32,
                    marginTop: 100,
                  }}
                >
                  <h4>
                    <b>Frequency of Math Terms</b>
                  </h4>
                </div>
                Total of all unique math terms used
                <div
                  style={{ fontSize: "60px", fontFamily: "Roboto, san-serif" }}
                >
                  {Math.round(math_terms.total_count)}
                </div>
                <div style={{ margin: 18 }}>
                  Top Three Math Terms Used (in order):
                  <div style={{ fontSize: 20, fontWeight: 600 }}>
                    {math_terms.total_most_frequent_1},{" "}
                    {math_terms.total_most_frequent_2},{" "}
                    {math_terms.total_most_frequent_3}
                  </div>
                </div>
                {teacherTermsCount + studentTermsCount > 0 && (
                  <div
                    style={{
                      marginTop: 18,
                      borderTop: "1px solid #ddd",
                      padding: 12,
                      paddingTop: 24,
                    }}
                  >
                    {studentTermsCount === 3 && (
                      <div
                        style={{
                          display: "inline-block",
                          width: "50%",
                          borderRight: "1px solid #ccc",
                        }}
                      >
                        Unique Math Terms by Students:{" "}
                        <b>{Math.round(math_terms.student.total_student)}</b>
                        <div style={{ margin: 18 }}>
                          Top Math Terms Used by Students (in order):
                          <div style={{ fontSize: 20, fontWeight: 600 }}>
                            {math_terms.student.most_frequent_1},{" "}
                            {math_terms.student.most_frequent_2},{" "}
                            {math_terms.student.most_frequent_3}
                          </div>
                        </div>
                      </div>
                    )}
                    <div style={{ display: "inline-block", width: "50%" }}>
                      Unique Math Terms by Teacher:{" "}
                      <b>{Math.round(math_terms.teacher.total_teacher)}</b>
                      <div style={{ margin: 18 }}>
                        Top Math Terms Used by Teacher (in order):
                        <div style={{ fontSize: 20, fontWeight: 600 }}>
                          {math_terms.teacher.most_frequent_1},{" "}
                          {math_terms.teacher.most_frequent_2},{" "}
                          {math_terms.teacher.most_frequent_3}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        fontSize: 12,
                        fontStyle: "italic",
                        textAlign: "left",
                        paddingTop: 18,
                      }}
                    >
                      (Note: Total Math Terms only includes unique terms used by
                      both students and teachers. So, there is likely overlap
                      and the total unique math terms is usually less than the
                      sum of the total student math terms and total teacher math
                      terms.)
                    </div>
                  </div>
                )}
              </div>
            </Row>
          </>
        )}
      </div>
    );
  }

  renderPage() {
    const { topMenuSelected } = this.state;
    return (
      <div id="lca" className="lessons-page">
        {this.renderLeftNav()}
        <div className="lessons-content-area">
          {(!topMenuSelected || topMenuSelected === "this-lesson") &&
            this.renderTopPanel()}
          {topMenuSelected === "menu-ttm" && this.renderTeacherPanel()}
          {topMenuSelected === "menu-stm" && this.renderStudentPanel()}
          {topMenuSelected === "menu-otm" && this.renderOtherPanel()}
          {/* Commenting out these redundant nav links
          {this.renderFeedbackButtons()} 
          */}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="">
        {/* {this.props.isAuthenticated ? this.renderPage() : this.renderLander()} */}
        {this.renderPage()}
        {this.state.hoverEl && (
          <div
            onMouseOut={this.closeHoverTip}
            className={"lessons-hover-tip"}
            style={{
              top: this.state.pageY,
              left: this.state.pageX,
              width: this.state.hoverWide ? 350 : 250,
            }}
          >
            {this.state.hoverTip}
          </div>
        )}
      </div>
    );
  }
}
